import Design1 from './design-1';
import Design2 from './design-2';
import Design3 from './design-3';
import Design4 from './design-4';

import './index.css';
import useApplyUserDefinedListOverrides from "../../hooks/use-user-defined-list-sort";
import {WebsiteContext} from "../../index";
import {useContext, useEffect, useState} from "react";
import axios from "axios";
import _get from "lodash/get";
import {liveContentFilter} from "../../utils/utils";

const makeItems = (children, fields) => {
    if (children?.length <= 0) return [];
    const items = liveContentFilter(children)?.map(child => {
        return {
            title: _get(child, fields.title, ""),
            text: _get(child, fields.text, ""),
            image: _get(child, fields.image, ""),
            altText: _get(child, fields.altText, ""),
            showingActionButton: !!_get(child, fields.action?.label, null),
            dataPosition: child.dataPosition,
            action: {
                label: _get(child, fields.action?.label, ""),
                type: _get(child, fields.action?.type, ""),
                uniqueUri: _get(child, fields.action?.uniqueUri, ""),
                value: _get(child, fields.action?.value, ""),
                target: _get(child, fields.action?.target, "_self"),
            },
            detailsPopup: {
                label: _get(child, fields.detailsPopup?.label, ""),
                text: _get(child, fields.detailsPopup?.text, ""),
            }
        }
    })
    return items.sort((a, b) => a.dataPosition - b.dataPosition);
}

export default function ListOfItemsBlock(props) {
    const context = useContext(WebsiteContext);
    const [items, setItems] = useState(props?.values?.items || []);

    const values = useApplyUserDefinedListOverrides({
        ...props,
        values: {
            ...props?.values,
            items: props?.values?.source === 'collection' ? items : props.values.items
        }
    })

    useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        if (context.builder && props?.values?.source === 'collection') {
            axios
                .get(`/api/v2/channels/${context.channel}/nodes/${props.values.schema}/`, {cancelToken: cancelToken.token})
                .then(({data}) => {
                    const children = data?.[0]?.children;
                    setItems(makeItems(children, props?.values?.item));
                })
                .catch(err => {
                    if (!axios.isCancel(err)) console.log(err);
                })
        } else if (props?.values?.source === 'collection') setItems(makeItems(props.values?.itemsData, props?.values?.item));
        return () => cancelToken.cancel();
    }, [props?.values]);

    switch (props.values?.design) {
        case 0 :
            return (<Design1 {...props} values={values}/>);
        case 1:
            return (<Design2 {...props} values={values}/>);
        case 2:
            return (<Design3 {...props} values={values}/>);
        case 3:
            return (<Design4 {...props} values={values}/>);
        default:
            return (<Design1 {...props} values={values}/>);
    }
}