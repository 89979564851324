import Block from '../block'
import {listColumns} from "./design-1";
import {
    backgroundColour,
    textAlignment,
    backgroundPattern,
    aspectRatio, setTitleDecorationByClass, setTitleDecorationByElement, objectFit
} from "../../utils/block-utils";
import classNames from "classnames";
import Action from "../../components/action";
import {Image} from "../../components/image";
import {Dialog, Transition} from "@headlessui/react";
import {useState, Fragment} from "react";

export default function Design2(props) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState({label: "", text: ""});

    const handleOpenModal = (content) => {
        setModalContent(content);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const clearModalContent = () => {
        setModalContent({label: "", text: ""});
    };

    return (
        <Block {...props}
               className={classNames(
                   props.className,
                   !props.values?.options?.inset ? backgroundPattern(props) : null,
                   !props.values?.options?.inset ? backgroundColour(props.values.options?.backgroundColour) : null)
               }>
            <div className={classNames(
                "mx-auto max-w-7xl section flex flex-col items-center",
                textAlignment(props),
                props.values?.options?.inset ? 'rounded-theme' : null,
                props.values?.options?.inset ? backgroundPattern(props) : null,
                props.values?.options?.inset ? backgroundColour(props.values.options?.backgroundColour) : null
            )}>
                <div className={"w-full"}>
                    {props.values?.title ? (
                        <>
                            <h3 className={classNames("relative", setTitleDecorationByClass(props.values?.options?.titleDecoration))}>{props.values.title}</h3>
                            {props.values?.options?.titleDecoration &&
                                setTitleDecorationByElement(props.values?.options?.titleDecoration)
                            }
                        </>
                    ) : null}
                    <div dangerouslySetInnerHTML={{__html: props.values.text}} className={"rich-text"}/>
                </div>
                <div className={classNames("relative w-full overflow-x-auto",
                    props.values.options?.listItemsMobileDisplay === "stack" ? null : "snap-mandatory snap-x sm:snap-none"
                )}>
                    <ul className={classNames(
                        "mb-4 lg:space-x-0 lg:grid lg:gap-x-8 lg:gap-y-12",
                        props.values.options.listItemsMobileDisplay === "stack" ? "block space-y-6 sm:space-y-0" : "inline-flex space-x-4",
                        listColumns(props)
                    )}>
                        {props.values.items?.map((item, i) => (
                            <li key={i} className={classNames(
                                "inline-flex flex-col snap-start rounded-theme overflow-hidden lg:w-auto",
                                props.values.options.listItemsMobileDisplay === "stack" ? "w-full" : "w-64",
                                props.values.options?.backgroundColour === 1 ? 'section-grey' : 'section-white'
                            )}>
                                <div className="group flex flex-1 flex-col relative h-full rounded-theme">
                                    <div className={classNames(
                                        "w-full overflow-hidden relative",
                                        aspectRatio(props)
                                    )}>
                                        {item.image ? (
                                            <Image
                                                id={item.imageState?.__cx__?.id ? `image_${item.imageState?.__cx__?.id}` : null}
                                                src={item.imageState?.__cx__?.key || item.image}
                                                key={item.image + item.imageState + props.values?.options?.imagePresentation}
                                                alt={item?.altText}
                                                className={classNames(
                                                    "lazyload lazymotion w-full h-full object-center !rounded-b-none",
                                                    objectFit(props.values.options?.imagePresentation),
                                                    item.action && item.action.type && !['button', 'buttonFull', 'link'].includes(props.values?.options?.actionStyle) ? 'group-hover:opacity-75' : ''
                                                )}
                                            />
                                        ) : null}
                                    </div>
                                    <div className={classNames("flex flex-col flex-1 p-4", textAlignment(props))}>
                                        <h6>
                                            {(!props.values?.options?.actionStyle || props.values?.options?.actionStyle === 'noStyle') &&
                                                <Action {...item}
                                                        action={{ ...item.action, label: undefined }}
                                                        itemActionStyle={props.values?.options?.actionStyle}
                                                        title={null}
                                                        button={false} 
                                                        defaultHref={'#'}>
                                                    <span className="absolute inset-0"/>
                                                </Action>
                                            }
                                            <span>{item.title}</span>
                                        </h6>
                                        {item.text ? (
                                            <div dangerouslySetInnerHTML={{__html: item.text}}
                                                 className="mt-1 rich-text"/>
                                        ) : null}
                                        {props.values?.options?.actionStyle !== 'noStyle' && props.values?.options?.actionStyle &&
                                            <div className={"mt-auto"}>
                                                <Action className={"mt-3 mb-2"} {...item} itemActionStyle={props.values?.options?.actionStyle} title={null} />
                                            </div>
                                        }
                                        {item?.detailsPopup &&
                                            <div className={"mt-auto pt-4"}>
                                                <button className={"opacity-80 text-sm hover:opacity-100"}
                                                        onClick={() =>
                                                            handleOpenModal(
                                                                {
                                                                    label: item.detailsPopup.label,
                                                                    text: item.detailsPopup.text
                                                                }
                                                            )
                                                        }
                                                >
                                                    {item?.detailsPopup?.label}
                                                </button>
                                            </div>
                                        }
                                    </div>
                                    
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                <Action className={"mt-6"} {...props.values} title={null}/>
            </div>
            <Transition appear show={isModalOpen} as={Fragment}
                        afterLeave={clearModalContent} >
                <Dialog
                    as="div"
                    className="relative z-10"
                    onClose={handleCloseModal}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-200"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/30"/>
                    </Transition.Child>
                    <div className="fixed inset-0 flex items-center justify-center p-4">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="relative">
                                <Dialog.Panel className="bg-white rounded-theme shadow-lg max-w-lg w-full mx-auto p-6 max-h-[75vh] mb-[calc(var(--site-nav-height)-16px)] overflow-y-auto" >
                                    <Dialog.Title
                                        as="h5"
                                        className="text-center text-gray-900"
                                    >
                                        {modalContent.label}
                                    </Dialog.Title>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: modalContent.text,
                                        }}
                                        className="rich-text"
                                    />
                                </Dialog.Panel>
                                <button
                                    onClick={handleCloseModal}
                                    className="absolute -bottom-1 mt-4 mx-auto left-1/2 transform -translate-x-1/2 border-2 border-gray-300 bg-white rounded-full shadow-lg overflow-hidden"
                                >
                                    <svg
                                        className="h-8 w-8 stroke-gray-800 bg-gray-100 p-1"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </Block>
    )
}
