import Block from '../block'
import {
    backgroundColour,
    textAlignment,
    frameType,
    backgroundPattern,
    aspectRatio, setTitleDecorationByClass, setTitleDecorationByElement, objectFit
} from "../../utils/block-utils";
import classNames from "classnames";
import Action from "../../components/action";
import {Image} from "../../components/image";

export function listColumns(props) {
    const { columns, columnsMobile } = props.values.options || {};
    const desktopClasses = (() => {
        switch (columns) {
            case 1: return 'lg:grid-cols-1';
            case 2: return 'lg:grid-cols-2';
            case 3: return 'lg:grid-cols-3';
            case 4: return 'lg:grid-cols-4';
            case 5: return 'lg:grid-cols-5';
            case 6: return 'lg:grid-cols-6';
            case 7: return 'lg:grid-cols-7';
            default: return 'lg:grid-cols-3';
        }
    })();

    const mobileClasses = columnsMobile !== undefined && columnsMobile !== null ? (() => {
        switch (columnsMobile) {
            case 1: return 'grid-cols-1';
            case 2: return 'grid-cols-2';
            case 3: return 'grid-cols-3';
            case 4: return 'grid-cols-4';
            default: return 'grid-cols-1'; 
        }
    })() : '';

    return `${mobileClasses} ${desktopClasses}`.trim();
}

export default function Design1(props) {
    return (
        <Block {...props}
               className={classNames(
                   props.className,
                   !props.values.options?.inset ? backgroundPattern(props) : null,
                   !props.values.options?.inset ? backgroundColour(props.values.options?.backgroundColour) : null)
               }>
            <div className={classNames(
                "mx-auto max-w-7xl section flex flex-col items-center",
                textAlignment(props),
                props.values.options?.inset ? 'rounded-theme' : null,
                props.values.options?.inset ? backgroundPattern(props) : null,
                props.values.options?.inset ? backgroundColour(props.values.options?.backgroundColour) : null
            )}>
                <div className={"w-full"}>
                    {props.values?.title ? (
                        <>
                            <h3 className={classNames("relative", setTitleDecorationByClass(props.values?.options?.titleDecoration))}>{props.values.title}</h3>
                            {props.values?.options?.titleDecoration &&
                                setTitleDecorationByElement(props.values?.options?.titleDecoration)
                            }
                        </>
                    ) : null}
                    {props.values.text ? (
                        <div dangerouslySetInnerHTML={{__html: props.values.text}} className={"rich-text"}/>
                    ) : null}
                </div>
                <div className={classNames("relative w-full overflow-x-auto",
                    props.values.options?.listItemsMobileDisplay === "stack" ? null : "snap-mandatory snap-x sm:snap-none"
                )}>
                    <ul className={classNames(
                        "mb-4 lg:space-x-0 lg:grid gap-x-4 gap-y-6 lg:gap-x-8 lg:gap-y-12 sm:text-[length:initial] sm:leading-[initial]",
                        props.values.options?.listItemsMobileDisplay === "stack" ? "grid" : "inline-flex space-x-4",
                        props.values.options?.listItemsMobileDisplay === "stack" &&
                        props.values.options?.columnsMobile >= 3 ? "text-xs" : "",
                        listColumns(props)
                    )}>
                        {props.values.items?.map((item, i) => (
                            <li key={i}
                                className={classNames("inline-flex flex-col overflow-hidden snap-start lg:w-auto",
                                    props.values.options?.listItemsMobileDisplay === "stack" ? "w-full" : "w-64"
                                )}>
                                <div className="group relative">
                                    {item.image ? (
                                        <div className={classNames(
                                            "w-full overflow-hidden relative",
                                            aspectRatio(props),
                                            frameType(props) === 'circle' ? 'clip-circle' : '',
                                            frameType(props) === 'slant' ? 'clip-slant !rounded-none' : '',
                                        )}>
                                            <Image
                                                id={item.imageState?.__cx__?.id ? `image_${item.imageState?.__cx__?.id}` : null}
                                                src={item.imageState?.__cx__?.key || item.image}
                                                key={item.image + item.imageState + props.values?.options?.imagePresentation}
                                                alt={item?.altText}
                                                className={classNames(
                                                    "lazyload lazymotion w-full h-full object-center rounded-theme",
                                                    objectFit(props.values.options?.imagePresentation),
                                                    item.action && item.action.type && !['button', 'buttonFull', 'link'].includes(props.values?.options?.actionStyle) ? 'group-hover:opacity-75' : '',
                                                    frameType(props) === 'slant' ? '!rounded-none' : '',
                                                )}
                                            />
                                        </div>
                                    ) : null}
                                    <div className={classNames("mt-4", textAlignment(props))}>
                                        <h6 className="mt-1">
                                            {(!props.values?.options?.actionStyle || props.values?.options?.actionStyle === 'noStyle') &&
                                                <Action {...item}
                                                        action={{ ...item.action, label: undefined }}
                                                        itemActionStyle={props.values?.options?.actionStyle} 
                                                        title={null}
                                                        button={false} defaultHref={'#'}>
                                                    <span className="absolute inset-0"/>
                                                </Action>
                                            }
                                            <span>{item.title}</span>
                                        </h6>
                                        {item.text ? (
                                            <div dangerouslySetInnerHTML={{__html: item.text}}
                                                 className="mt-1 rich-text"/>
                                        ) : null}
                                        {props.values?.options?.actionStyle !== 'noStyle' && props.values?.options?.actionStyle &&
                                            <Action className={props.values.options?.actionStyle === 'link' ? "mt-3" : "mt-6"} {...item} itemActionStyle={props.values?.options?.actionStyle} title={null} />
                                        }
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                <Action className={"mt-6"} {...props.values} title={null}/>
            </div>
        </Block>
    )
}